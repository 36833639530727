.default-row-spacer {
  margin-bottom: 1rem;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.agenda {
  min-height: calc(1.5em + 0.75rem + 100px) !important;
}

.imageInput {
  text-align: center;
}

.profileImageContainer {
  max-height: 200px;
  max-width: 200px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.profileImage {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.tutorialVideo {
  width: 50% !important;
  max-width: none !important;
}

.accordionButton {
  margin-left: 20px;
}

.top-header {
  margin-top: 2rem;
  margin-bottom: 1rem;
  text-align: center;
}

.topSpacer {
  margin-top: 20px;
}

.userProfileButtonGroup {
  display: flex !important;
}

.userProfileFormGroup {
  margin-top: 40px;
}

.tutorialButtons {
  margin-top: 20px !important;
}

.generalTextArea {
  min-height: calc(1.5em + 0.75rem + 150px) !important;
}
.regularItem {
  text-align: center;
  margin-bottom: 1rem;
  z-index: 0;
}

.rightItem {
  margin-bottom: 1rem;
  display: flex;
  justify-content: right;
}

.rowButton {
  margin-right: 5px;
}

.rightOfTwo {
  display: flex;
  margin-left: auto;
}

.leftOfTwo {
  display: flex;
  margin-right: auto;
}

.verticalAlignCol {
  display: flex;
  align-items: center; /* align vertical */
}

.flexCol {
  display: flex;
}

.titleNavbar {
  justify-content: center;
  margin-top: 1rem;
  font-weight: bold;
}

.titleText {
  font-size: 1.5rem;
}

.logo {
  margin-right: 10px;
}

.nav-tabs {
  justify-content: right;
}

.login {
  margin-top: 50px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.centerButton {
  text-align: center;
}

.refreshButton {
  margin-right: 20px;
}

.rbc-allday-cell {
  display: none;
}

.modalCardCol {
  overflow-y: scroll;
  max-height: calc(100vh - 200px);
}

.seeMoreDiv {
  justify-content: center;
  margin-top: 10px !important;
}

.seeMoreButton {
  max-width: 100px !important;
}

.worldCalendar .rbc-event-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rbc-time-slot {
  min-height: 40px;
}

/* slider */
.horizontal-slider {
  width: 100%;
  height: 60px;
  margin: auto;
}

.example-thumb {
  cursor: pointer;
  position: absolute;
  z-index: 100;
  background: #ffffff;
  border: 10px solid #3774ff;
  border-radius: 100%;
  display: block;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
}

.example-thumb.active {
  background-color: grey;
}

.example-track {
  position: relative;
  background: #ddd;
}

.example-track.example-track-1 {
  background: #83a9ff;
}

.horizontal-slider .example-track {
  top: 20px;
  height: 4px;
}

.horizontal-slider .example-thumb {
  top: 12px;
  width: 10px;
  outline: none;
  height: 10px;
  line-height: 38px;
}
